import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"
import Lottie from "lottie-react"
import heroAnimation from "../../static/hero.json"

const fadein = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`

const Svg = styled.svg`
  #phone {
    opacity: 0;
    animation: ${fadein} 0.5s linear forwards;
  }
  #truck {
    opacity: 0;
    animation: ${fadein} 0.25s linear 0.5s forwards;
  }
  #dot-1 {
    opacity: 0;
    animation: ${fadein} 0.25s linear 0.75s forwards;
  }
  #dot-2 {
    opacity: 0;
    animation: ${fadein} 0.25s linear 1s forwards;
  }
  #dot-3 {
    opacity: 0;
    animation: ${fadein} 0.25s linear 1.25s forwards;
  }
  #house {
    opacity: 0;
    animation: ${fadein} 0.25s linear 1.5s forwards;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 650px;
  height: 0;
  padding-bottom: 105%;
  position: relative;
`

const HeroSvg = () => {
  return (
    <ImageWrapper>
      <Lottie animationData={heroAnimation} loop={false} autoplay={true} />
    </ImageWrapper>
  )
}

export default HeroSvg
